<template>
  <page-header-wrapper :title='this.$route.meta.title'>
    <div>
      <div class='table-operator'>
        <a-button type='primary' icon='plus' @click='handleNew()'>新建</a-button>
        <a-divider type='vertical' />
        <a-button type='primary' icon='reload' @click='$refs.table.refresh(true)'>刷新</a-button>
        <a-button style="float: right" type='primary' icon='global' @click='handleWhiteList()'>ip白名单</a-button>
      </div>

      <s-table ref='table' rowKey='id' :columns='columns' :scroll='{ x: "100%"}' :data='loadData'>
        <span slot='action' slot-scope='text, record'>
          <template>
            <a @click='handleEdit(record)'>编辑</a>
            <span>
                  <a-divider type='vertical' />
                  <a @click='handleDelete(record)'>删除</a>
            </span>
          </template>
        </span>
        <span slot='avatar' slot-scope='text'>
          <template>
            <a-avatar v-if='text' :src='text' />
            <a-avatar v-else icon='user' />
          </template>
        </span>
        <span slot='status' slot-scope='text'>
          <template>
            <a-badge v-if='text==0' dot status='default' :text='"注销"' />
            <a-badge v-if='text==1' dot status='processing' :text='"有效"' />
          </template>
        </span>
      </s-table>
    </div>
  </page-header-wrapper>
</template>

<script>
import { STable, Ellipsis } from '@/components'
import { queryOperators, removeOperator } from '@/api/operator'

const columns = [
  {
    width: 200,
    title: '#',
    dataIndex: 'id',
    scopedSlots: { customRender: 'id' }
  },
  {
    width: 200,
    title: '用户名称',
    dataIndex: 'nickname',
    scopedSlots: { customRender: 'nickname' }
  },
  {
    width: 180,
    title: '用户头像',
    dataIndex: 'avatar',
    scopedSlots: { customRender: 'avatar' }
  },
  {
    width: 150,
    title: '登录账号',
    dataIndex: 'username',
    scopedSlots: { customRender: 'username' }
  },
  {
    width: 150,
    title: '绑定手机',
    dataIndex: 'mobile',
    scopedSlots: { customRender: 'mobile' }
  },
  {
    width: 100,
    title: '用户状态',
    dataIndex: 'status',
    scopedSlots: { customRender: 'status' }
  },
  {
    width: 200,
    title: '创建时间',
    dataIndex: 'createTime'
  },
  {
    width: 200,
    title: '操作',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' }
  }
]

export default {
  name: 'OperatorList',
  components: {
    STable, Ellipsis
  },
  data() {
    return {
      // 表头
      columns,
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return queryOperators(Object.assign(parameter, this.queryParam))
          .then(datum => {
            return datum
          })
      }
    }
  },
  created() {

  },
  methods: {
    handleNew() {
      this.$router.push('/permission/operator/edit')
    },
    handleEdit(record) {
      this.$router.push({ path: '/permission/operator/edit', query: { id: record.id } })
    },
    handleWhiteList() {
      this.$router.push({ path: '/permission/whitelist/list'})
    },
    handleSticky(record, status) {
      sticky({ id: record.id, sticky: status }).then(result => {
        this.$message.success('操作成功')
        this.$refs.table.refresh(true)
      })
    },
    handleDelete(record) {
      removeOperator({ id: record.id }).then(result => {
        this.$message.success('操作成功')
        this.$refs.table.refresh(true)
      })
    },
    handleView(url) {
      window.open(url, '_blank')
    }
  }
}
</script>
